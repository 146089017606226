import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import {
  DocumentClass,
  DocumentClassBatchCreateDto,
  DocumentClassCreateDto,
  DocumentClassFilter,
  DocumentClassUpdateDto
} from 'src/app/graphql/frontend-data-graphql';

export const DocumentClassUiActions = createActionGroup({
  source: 'DocumentClass UI',
  events: {
    // Form actions
    Add: emptyProps(),
    Duplicate: emptyProps(),

    // Tree Actions
    SelectionChanged: props<{ identifier: string[] }>(),
    SubtreeRootChanged: props<{ rootId: string | null }>(),
    ExpandedKeysChanged: props<{ identifier: string; isExpanded: boolean; expandedIdentifiers: string[] }>(),

    // Filtering Actions
    SetShowDeleted: props<{ showDeleted: boolean }>(),
    SearchTriggered: props<{ q: string }>(),

    // Api Trigger Actions
    CreateTriggered: props<{ input: DocumentClassCreateDto }>(),
    CreateBatchTriggered: props<{ input: DocumentClassBatchCreateDto; parentIds: string[] }>(),
    UpdateOneTriggered: props<{ identifier: string; update: DocumentClassUpdateDto }>(),
    UpdateBatchTriggered: props<{ update: DocumentClassUpdateDto; identifiers: string[] }>(),
    DeleteOneTriggered: props<{ identifier: string }>(),
    DeleteBatchTriggered: props<{ identifiers: string[] }>(),
    RestoreOneTriggered: props<{ identifier: string }>(),
    RestoreBatchTriggered: props<{ identifiers: string[] }>(),
    ReorderTriggered: props<{ identifier: string; idx: number }>(),
    DuplicateOneTriggered: props<{ input: DocumentClassCreateDto; referenceId: string }>(),

    // implicitly triggered by the UI
    FindManyChildrenTriggered: props<{ parentId: string | null; clearBeforehand: boolean }>(),
    FindSubTreeRootTriggered: props<{ identifier: string | null }>()
  }
});

export const DocumentClassApiActions = createActionGroup({
  source: 'DocumentClass API',
  events: {
    FindManyChildrenSucceeded: props<{ items: Array<Partial<DocumentClass>>; parentId: string | null }>(),
    FindSubTreeRootSucceeded: props<{ item: DocumentClass | null }>(),
    PrefillFormSucceeded: props<{ value: Partial<DocumentClass>; mode: 'create' | 'update' | 'duplicate'; selectedIds: string[] }>(),
    SearchSucceeded: props<{ items: Array<Partial<DocumentClass>>; q: string }>(),

    MutationSucceeded: props<{ mode: 'create' | 'update' | 'duplicate' | 'delete'; identifiers: string[] }>(),
    Created: props<{ item: Partial<DocumentClass> }>(),
    UpdatedOne: props<{ item: Partial<DocumentClass> }>(),
    DeletedOne: props<{ item: Partial<DocumentClass> }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
