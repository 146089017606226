import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { P } from 'ts-pattern';

type Deletable = { deletedAt?: Date | null };

export type GeneralTreeNodeOptions<E extends Deletable = Deletable> = NzTreeNodeOptions & { raw: E };
export interface GeneralTreeNode<E extends Deletable = Deletable> {
  id: string;
  data: GeneralTreeNodeOptions<E>;
  children: Array<GeneralTreeNode<E>>;
}

const ROOT_ID = 'NULL';

export type GeneralTree<E extends Deletable = Deletable> = {
  nodesMap: Record<string, GeneralTreeNode<E>>;
  root: GeneralTreeNode<E>;
};

export function buildEmptyTree<E extends Deletable>(rootId: string | null): GeneralTree<E> {
  return {
    root: { id: rootId ?? ROOT_ID, data: { title: '', key: '', raw: {} as E }, children: [] },
    nodesMap: {}
  };
}

export function insertNodes<E extends Deletable>(
  input: GeneralTree,
  data: GeneralTreeNodeOptions[],
  parentId: string | null
): GeneralTree<E> {
  const tree = structuredClone(input);
  const parentNode = parentId === null || tree.root.children.length === 0 ? tree.root : tree.nodesMap[parentId];
  if (parentNode) {
    for (const node of data) {
      const newNode = createNode(tree, node);
      parentNode.children.push(newNode);
      parentNode.data.expanded = true;
    }
  } else {
    throw new Error('Parent node not found');
  }
  return tree as GeneralTree<E>;
}

export function insertCreatedNode<E extends Deletable>(
  input: GeneralTree<E>,
  node: GeneralTreeNodeOptions<E>,
  parentId: string | null,
  sortFn: (a: GeneralTreeNode<E>, b: GeneralTreeNode<E>) => number
): GeneralTree<E> {
  const tree = structuredClone(input);
  const parentNode =
    parentId === null || tree.root.children.length === 0 || parentId === tree.root.id ? tree.root : tree.nodesMap[parentId];
  if (parentNode && (parentNode.data.isLeaf || parentNode.data.expanded)) {
    // skip if siblings are not expanded yet
    const newNode = createNode(tree, node);
    parentNode.children.push(newNode);
    parentNode.children.sort(sortFn);
    parentNode.data.isLeaf = false;
    parentNode.data.expanded = true;
  } else {
    console.info('[Tree]: Parent of created node not found or not expanded');
  }
  return tree as GeneralTree<E>;
}

export function updateNode<E extends Deletable>(
  input: GeneralTree<E>,
  update: GeneralTreeNodeOptions<E>,
  parentId: string | null,
  sortFn: (a: GeneralTreeNode<E>, b: GeneralTreeNode<E>) => number
): GeneralTree<E> {
  const tree = structuredClone(input);
  const matchingNode = tree.nodesMap[update.key];
  if (matchingNode) {
    matchingNode.data = { ...matchingNode.data, ...update };
  } else {
    console.info(`[Tree]: Skip update of node ${update.key} because it was not found`);
    return tree;
  }
  const parentNode = parentId === null || tree.root.children.length === 0 ? tree.root : tree.nodesMap[parentId];
  if (parentNode) {
    // re-sort because order weight might have changed
    parentNode.children.sort(sortFn);
  } else {
    console.warn('[Tree]: Parent of updated node not found');
  }
  return tree as GeneralTree<E>;
}

export function removeNode<E extends Deletable>(input: GeneralTree<E>, id: string, parentId: string | null): GeneralTree<E> {
  const tree = structuredClone(input);
  if (tree.nodesMap[id]) {
    delete tree.nodesMap[id];
  } else {
    console.info(`[Tree]: Skip deletion of node ${id} because it was not found`);
    return tree;
  }
  const parentNode = parentId === null || tree.root.children.length === 0 ? tree.root : tree.nodesMap[parentId];
  if (parentNode) {
    parentNode.children = parentNode.children.filter(child => child.id !== id);
  } else {
    console.warn('[Tree]: Parent of deleted node not found');
  }
  return tree as GeneralTree<E>;
}

export function toggleExpanded<E extends Deletable>(input: GeneralTree, identifier: string, isExpanded: boolean): GeneralTree<E> {
  const tree = structuredClone(input);
  const node = tree.nodesMap[identifier];
  if (node) {
    node.data.expanded = isExpanded;
  } else {
    throw new Error('Parent node not found');
  }
  return tree as GeneralTree<E>;
}

function createNode<E extends Deletable>(tree: GeneralTree<E>, data: GeneralTreeNodeOptions<E>): GeneralTreeNode<E> {
  const node: GeneralTreeNode<E> = {
    id: data.key,
    data: data,
    children: []
  };
  tree.nodesMap[node.id] = node;
  return node;
}

export function toNzTreeNodeOptions(tree: GeneralTree, withDeleted = true): NzTreeNodeOptions[] {
  return structuredClone(mapToNzTreeNodeOptions(tree.root, withDeleted).children!);
}

function mapToNzTreeNodeOptions(node: GeneralTreeNode, withDeleted: boolean): NzTreeNodeOptions {
  return {
    ...node.data,
    children: node.children
      .filter(child => (withDeleted ? true : !child.data.raw.deletedAt))
      .map(child => mapToNzTreeNodeOptions(child, withDeleted))
  };
}
