import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { DocumentClass } from 'src/app/graphql/frontend-data-graphql';

import { DocumentClassApiActions, DocumentClassUiActions } from './document-class.actions';
import {
  buildEmptyTree,
  GeneralTree,
  GeneralTreeNode,
  GeneralTreeNodeOptions,
  insertCreatedNode,
  insertNodes,
  toggleExpanded,
  toNzTreeNodeOptions,
  updateNode
} from './util/tree.util';

export const documentClassesFeatureKey = 'documentClass';

const treeSortFn = (a: GeneralTreeNode<DocumentClass>, b: GeneralTreeNode<DocumentClass>) => {
  // Compare deletedAt, with nulls first
  if (!a.data.raw.deletedAt && b.data.raw.deletedAt) return -1;
  if (a.data.raw.deletedAt && !b.data.raw.deletedAt) return 1;
  if (a.data.raw.deletedAt && b.data.raw.deletedAt) {
    if (a.data.raw.deletedAt < b.data.raw.deletedAt) return -1;
    if (a.data.raw.deletedAt > b.data.raw.deletedAt) return 1;
  }

  // If deletedAt is equal or both null, compare orderWeight
  // Assuming orderWeight is a number and defined for all elements
  return a.data.raw.orderWeight! - b.data.raw.orderWeight!;
};
export interface DocumentClassState extends EntityState<DocumentClass> {
  tree: GeneralTree<DocumentClass>;
  isLoading: boolean;
  showDeleted: boolean;
  selectionIds: string[];
  searchToken: string;
  searchResults: GeneralTree<DocumentClass>;
  subtreeRoot: DocumentClass | null;
  formAction: 'create' | 'update' | 'duplicate';
  formItem: Partial<DocumentClass>; // prefilled value
  errors: readonly GraphQLError[];
  isUpdating: boolean;
}

export const adapter: EntityAdapter<DocumentClass> = createEntityAdapter<DocumentClass>();

export const initialState: DocumentClassState = adapter.getInitialState({
  tree: buildEmptyTree(null),
  isLoading: true,
  showDeleted: true,
  selectionIds: [],
  searchToken: '',
  searchResults: buildEmptyTree(null),
  subtreeRoot: null,
  formAction: 'update',
  formItem: {},
  errors: [],
  isUpdating: false
});

export const documentClassReducer = createReducer(
  initialState,
  on(
    DocumentClassUiActions.findManyChildrenTriggered,
    (state, { clearBeforehand, parentId }): DocumentClassState => ({
      ...state,
      isLoading: true,
      tree: clearBeforehand ? buildEmptyTree(parentId) : state.tree
    })
  ),
  on(
    DocumentClassUiActions.updateOneTriggered,
    DocumentClassUiActions.updateBatchTriggered,
    (state): DocumentClassState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    DocumentClassApiActions.findManyChildrenSucceeded,
    (state, { items, parentId }): DocumentClassState => ({
      ...state,
      isLoading: false,
      tree: insertNodes(
        state.tree,
        items.map(
          e =>
            ({
              title: e.displayName!,
              key: e.identifier!,
              isLeaf: (e.children?.totalCount ?? 0) <= 0,
              selected: false,
              expanded: false,
              raw: e
            }) as GeneralTreeNodeOptions<DocumentClass>
        ),
        parentId
      )
    })
  ),
  on(
    DocumentClassApiActions.searchSucceeded,
    (state, { items, q }): DocumentClassState => ({
      ...state,
      isLoading: false,
      searchToken: q,
      searchResults: insertNodes(
        buildEmptyTree(null),
        items.map(
          e =>
            ({
              title: e.displayName!,
              key: e.identifier!,
              isLeaf: true,
              selected: false,
              expanded: false,
              raw: e
            }) as GeneralTreeNodeOptions<DocumentClass>
        ),
        null
      )
    })
  ),
  on(
    DocumentClassApiActions.findSubTreeRootSucceeded,
    (state, { item }): DocumentClassState => ({ ...state, subtreeRoot: item, searchToken: '' })
  ),
  on(
    DocumentClassApiActions.prefillFormSucceeded,
    (state, { value, mode, selectedIds }): DocumentClassState => ({
      ...state,
      formItem: value,
      formAction: mode,
      selectionIds: Array.from(new Set(selectedIds))
    })
  ),
  on(
    DocumentClassUiActions.expandedKeysChanged,
    (state, { identifier, isExpanded }): DocumentClassState => ({
      ...state,
      tree: toggleExpanded(state.tree, identifier, isExpanded)
    })
  ),
  on(DocumentClassUiActions.setShowDeleted, (state, { showDeleted }): DocumentClassState => ({ ...state, showDeleted })),
  // server side actions:
  on(
    DocumentClassApiActions.created,
    (state, { item }): DocumentClassState => ({
      ...state,
      tree: insertCreatedNode<DocumentClass>(
        state.tree,
        {
          title: item.displayName!,
          key: item.identifier!,
          isLeaf: true,
          selected: true,
          expanded: false,
          raw: item as DocumentClass
        },
        item.parentId ?? null,
        treeSortFn
      )
    })
  ),
  on(
    DocumentClassApiActions.updatedOne,
    DocumentClassApiActions.deletedOne,
    (state, { item }): DocumentClassState => ({
      ...state,
      tree: updateNode<DocumentClass>(
        state.tree,
        {
          title: item.displayName!,
          key: item.identifier!,
          selected: true,
          raw: item as DocumentClass
        },
        item.parentId ?? null,
        treeSortFn
      ),
      isUpdating: false
    })
  )
);

export const documentClassesFeature = createFeature({
  name: documentClassesFeatureKey,
  reducer: documentClassReducer,
  extraSelectors: ({
    selectDocumentClassState,
    selectSelectionIds,
    selectTree,
    selectSearchResults,
    selectFormItem,
    selectShowDeleted
  }) => ({
    ...adapter.getSelectors(selectDocumentClassState),
    selectSelectedCount: createSelector(selectSelectionIds, ids => ids.length),
    selectSelectedIds: createSelector(selectSelectionIds, ids => Array.from(ids)),
    selectSelectedClass: createSelector(selectSelectionIds, selectFormItem, (ids, item) => (ids.length === 1 ? item : null)),
    selectBatchEditValue: createSelector(selectSelectionIds, selectFormItem, (ids, item) => (ids.length > 1 ? item : null)),
    selectNzTree: createSelector(selectTree, selectShowDeleted, (tree, showDeleted) => toNzTreeNodeOptions(tree, showDeleted)),
    selectSearchNzTree: createSelector(selectSearchResults, selectShowDeleted, (tree, showDeleted) =>
      toNzTreeNodeOptions(tree, showDeleted)
    )
  })
});

export const {
  selectIsLoading,
  selectSubtreeRoot,
  selectFormAction,
  selectFormItem,
  selectSelectedCount,
  selectSelectedIds,
  selectSelectedClass,
  selectNzTree,
  selectSearchNzTree,
  selectShowDeleted,
  selectIsUpdating,
  selectSearchToken
} = documentClassesFeature;
